// @flow
import React, {Fragment} from 'react';
import Header from '../Header';
import CookieConsentWrapper from '../CookieConsentWrapper';
import styles from './Privacy.module.scss';

const Privacy = () => {
  return (
    <Fragment>
      <Header/>
      <div className={ `${styles['privacy__container']}` }>
        <h1 className='title'>Privacy Policy</h1>
        <div>
          <b>PRESENTATION OF THE SITE</b>
          <br/>Owner: Ari Bajo Rouvinen - 128 rue La Boétie, Paris, 75008, France
          <br/>Publication Manager: Ari Bajo Rouvinen – ari@guriosity.com
          <br/>Web hosting: Netlify, 2325 3rd Street, Suite 215, San Francisco, California 94107
          <br/>
          <br/><b>GENERAL CONDITIONS</b>
          <br/>Using https://guriosity.com site implies full acceptance of the following general conditions of use.
          <br/>
          <br/><b>COOKIE POLICY</b>
          <br/>Navigation on the site https://guriosity.com may cause the installation of cookie(s) on the user's computer. A cookie is a small file, which does not allow the identification of the user, but which records information related to the navigation of a computer on a site. The data thus obtained are intended to facilitate subsequent navigation on the site, and are also intended to allow various measures of attendance. The refusal to install a cookie may result in the impossibility of accessing the certain services.
          <br/>
          <br/>However, the user can configure his computer in the following way, to refuse the installation of cookies:
          <br/>- In Internet Explorer: tool tab (pictogram in form of cog in top on the right) / Internet options. Click Privacy and choose Block all cookies. Validate on Ok.
          <br/>- In Firefox: at the top of the browser window, click on the Firefox button, then go to the Options tab. Click on the Privacy tab. Set up the Conservation Rules on: use the custom settings for history. Finally uncheck it to disable cookies.
          <br/>- In Safari: Click at the top right of the browser on the menu pictogram (symbolized by a cog). Select Settings. Click Show Advanced Settings. In the "Privacy" section, click Content Settings. In the "Cookies" section, you can block cookies.
          <br/>- Under Chrome: Click on the top right of the browser on the menu icon (symbolized by three horizontal lines). Select Settings. Click Show Advanced Settings. In the "Privacy" section, click Preferences. In the "Privacy" tab, you can block cookies.
          <br/>
          <br/><b>PERSONAL DATA</b>
          <br/>In France, personal data are protected in particular by the law n ° 78-87 of January 6th, 1978, the law n ° 2004-801 of August 6th, 2004, article L 226-13 of the Penal Code and the European Directive of October 24, 1995.
          <br/>
          <br/>We receive and store any information you enter on our website or give us by phone, email or in any other way. On our website, you may request information, subscribe to our newsletter, purchase our products and services, participate in our contests and surveys, and use any other services we provide. To take advantage of these offerings, it is necessary for you to disclose personal information to us, including but not limited to one or more of the following identifying information: name, telephone number, email address. We use the information you provide to respond to your requests, communicate with you, provide information and promotional material from some of our partners, and customize and improve our services.
          <br/>
          <br/>Users who visit this website and have JavaScript enabled are tracked through Google Analytics. Google Analytics collects information anonymously and reports website trends to us without identifying individual visitors. Google Analytics collects a variety of information from users, including  the Internet protocol (IP address) that is used to connect your computer to the Internet (which it does not report to us), your Internet service provider (ISP), browser type, type of operating system, the full Uniform Resource Locator (URL) clickstream. date and time, cookie, the length of time you spend on particular pages, which links you click while on our site, and similar site visit information. Google Analytics data is shared with Google. For more information on Google’s privacy policy, visit https://policies.google.com/privacy.
          <br/>
          <br/>We use Mailchimp to send Newsletter emails to users who subscribe to receive them. You can review Mailchimp privacy policy in https://mailchimp.com/legal/privacy/.
          <br/>
          <br/>We use Netlify to host this website. You can review Netlify privacy policy in https://www.netlify.com/privacy/.
          <br/>
          <br/>In accordance with the provisions of Article 38 and following of Law 78-17 of 6 January 1978 relating to data, files and freedoms, any user has a right of access, rectification and opposition to the personal data concerning him, by making his written and signed request, accompanied by a copy of the identity document with signature of the holder of the document, specifying the address to which the answer must be sent.
          <br/>
          <br/>No personal information of the user of the site https://guriosity.com is published without the knowledge of the user, exchanged, transferred, assigned or sold on any support to others. Only the assumption of redemption and rights allow the transmission of such information to the prospective purchaser who would in turn be given the same obligation to store and modify data with respect to the user of the site https://guriosity.com.
          <br/>
          <br/>The databases are protected by the provisions of the law of 1 July 1998 transposing Directive 96/9 of 11 March 1996 on the legal protection of databases.
          <br/>
          <br/>The user provides this information with full knowledge of the facts, in particular when it proceeds by themselves to their input.
          <br/>
          <br/><b>LINKS TO OTHER SITES</b>
          <br/>The site https://guriosity.com may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
          <br/>
          <br/><b>CHANGES TO THIS PRIVACY POLICY</b>
          <br/>We may update or amend this Privacy Policy at any time. This Privacy Policy will reflect the date it was last updated or amended. If we make any material amendments, we will notify you by sending an email to the address, associated with your user account. All amendments will take effect immediately upon our posting of the updated Privacy Policy on this Website. Your continued use of this Website (publishing content) will indicate your acceptance of the changes to the Privacy Policy.
          <br/>
          <br/>If you have questions with respect to this Privacy Policy, please feel free to contact us by using our email contact@guriosity.com
          <br/>
          <br/>LAST UPDATE: 22/10/2019
        </div>
      </div>
      <CookieConsentWrapper/>
    </Fragment>
  );
}

export default Privacy;
