// @flow
import React from 'react';
import Privacy from '../components/Privacy';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const PrivacyTemplate = ({data}) => {

	return (
		<Layout customSEO>
			<SEO title='Privacy Policy' description='How we protect your data'/>
			<Privacy data={data}/>
		</Layout>
	)
};

export default PrivacyTemplate;
